import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useAppDispatch } from "../store/hooks";
import { changeServe } from "../store/matchSlice";

const Toolbar = () => {
  const dispatch = useAppDispatch();
  return (
    <Paper elevation={3} sx={{ py: 1 }}>
      <Stack direction="row">
        <Button variant="outlined" sx={{ height: "40px", flex: 1 }}>
          Undo
        </Button>
        <Button variant="outlined" sx={{ height: "40px", flex: 1 }}>
          Refresh
        </Button>
        <Button
          variant="outlined"
          sx={{ height: "40px", flex: 1, lineHeight: 1 }}
        >
          Change Court
        </Button>
        <Button
          variant="outlined"
          sx={{ height: "40px", flex: 1, lineHeight: 1, textTransform: "none" }}
          onClick={() => dispatch(changeServe())}
        >
          Change Serve
        </Button>
        <Button
          variant="outlined"
          sx={{ height: "40px", flex: 1, lineHeight: 1 }}
        >
          Change Server
        </Button>
        <Button
          variant="outlined"
          sx={{ height: "40px", flex: 1, lineHeight: 1 }}
        >
          Player Statistics
        </Button>
        <Button
          variant="outlined"
          sx={{ height: "40px", flex: 1, lineHeight: 1 }}
        >
          Play History
        </Button>
        <Button variant="outlined" sx={{ height: "40px", flex: 1 }}>
          Info
        </Button>
        <Button variant="outlined" sx={{ height: "40px", flex: 1 }}>
          Exit
        </Button>
      </Stack>
    </Paper>
  );
};

export default Toolbar;
