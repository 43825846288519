import React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { MatchState, CourtType } from "../store/MatchState";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { fault, getMatchState, point } from "../store/matchSlice";
interface PointReasonProps {
  serverType: CourtType;
}
const PointReason: React.FC<PointReasonProps> = ({ serverType }) => {
  const matchState = useAppSelector(getMatchState) as MatchState;
  const dispatch = useAppDispatch();
  return (
    <ButtonGroup orientation="vertical">
      <Button sx={{ height: "40px" }}>ACE</Button>
      <Button sx={{ height: "40px" }}>WINNER</Button>
      <Button
        sx={{ height: "40px" }}
        onClick={() => {
          if (!matchState.isFirstServer) dispatch(point("DoubleFault"));
          else dispatch(fault());
        }}
        disabled={matchState.server !== serverType}
      >
        {matchState.server !== serverType || matchState.isFirstServer
          ? "FAULT"
          : "DOUBLE FAULT"}
      </Button>
      <Button sx={{ height: "40px", lineHeight: 1 }}>UNFORCE ERROR</Button>
    </ButtonGroup>
  );
};

export default PointReason;
