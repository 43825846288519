import React from "react";
import Container from "@mui/material/Container";
import MatchScore from "./MatchScore";
import MatchTime from "./MatchTime";
import GameScore from "./GameScore";
import Toolbar from "./Toolbar";
import { PlayerState } from "../store/MatchState";
import { useAppSelector } from "../store/hooks";
import { getMatchState } from "../store/matchSlice";

interface ScoreboardProps {}

const Scoreboard: React.FC<ScoreboardProps> = () => {
  const matchState = useAppSelector(getMatchState);

  const [server, setServer] = React.useState<PlayerState>(matchState.player1);
  const [receiver, setReceiver] = React.useState<PlayerState>(
    matchState.player2
  );
  const handleOnPlayerChange = (player1: PlayerState, player2: PlayerState) => {
    setServer(player1);
    setReceiver(player2);
  };
  return (
    <Container>
      <MatchScore player1={matchState.player1} player2={matchState.player2} />
      <MatchTime player1={server.name} player2={receiver.name} />
      <GameScore
        server={server}
        receiver={receiver}
        onPlayerChange={handleOnPlayerChange}
      />
      <Toolbar />
    </Container>
  );
};

export default Scoreboard;
