import React from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import PointReason from "./PointReason";
import GamePoint from "./GamePoint";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { addPoint, getMatchState, updateGameStatus } from "../store/matchSlice";
import { PlayerState } from "../store/MatchState";

interface GameScoreProps {
  server: PlayerState;
  receiver: PlayerState;
  onPlayerChange: (server: PlayerState, receiver: PlayerState) => void;
}

const GameScore: React.FC<GameScoreProps> = ({
  server,
  receiver,
  onPlayerChange,
}) => {
  const matchState = useAppSelector(getMatchState);
  const dispatch = useAppDispatch();
  const [serverState, setServerState] = React.useState(
    matchState.AdCourtPlayers
  );
  const [receiverState, setReceiverState] = React.useState(
    matchState.DeuceCourtPlayers
  );
  React.useEffect(() => {
    setServerState(server);
    setReceiverState(receiver);
  }, [receiver, server]);

  // function updatePlayerScore(player: PlayerState): PlayerState {
  //   const updatedScores = player.setScore.map((score, index) =>
  //     //index === player.currentSet - 1 ? score + 1 : score
  //   );
  //   return { ...player, setScore: updatedScores };
  // }
  const handleGamePointChange = (apoint: number, bpoint: number) => {
    let player1 = serverState;
    let player2 = receiverState;
    let status = "";

    const isDeuce = apoint >= 3 && bpoint >= 3 && apoint === bpoint;
    const isGamePoint =
      (apoint >= 4 || bpoint >= 4) && Math.abs(apoint - bpoint) >= 2;
    if (isDeuce) {
      status = "DEUCE";
      apoint = Math.min(apoint, 3);
      bpoint = Math.min(bpoint, 3);
    } else if (isGamePoint) {
      if (apoint > bpoint) {
        //player1 = updatePlayerScore(player1);
      } else {
        // player2 = updatePlayerScore(player2);
      }
      apoint = 0;
      bpoint = 0;
    } else if (apoint >= 3 || bpoint >= 3) {
      status = "GAME POINT";
    }
    dispatch(updateGameStatus(status));
    dispatch(
      addPoint({ serverType: "AdCourt", reason: "Point", gamePoint: apoint })
    );
    dispatch(
      addPoint({ serverType: "DeuceCourt", reason: "Point", gamePoint: bpoint })
    );
    player1 = { ...player1, gameScore: apoint };
    player2 = { ...player2, gameScore: bpoint };

    onPlayerChange(player1, player2);
  };
  return (
    <Paper elevation={2}>
      <Stack direction="row" spacing={1}>
        <Stack sx={{ flex: 2 }}>
          <PointReason serverType="AdCourt" />
        </Stack>
        <Stack sx={{ flex: 8 }}>
          <Stack
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 3,
            }}
          >
            {matchState.gameStatus}
          </Stack>
          <Stack direction="row" spacing={1} sx={{ flex: 9 }}>
            <Stack sx={{ flex: 1 }}>
              <GamePoint
                score={serverState.gameScore}
                onChange={(s) => {
                  dispatch(addPoint({ serverType: "AdCourt", reason: "Point", gamePoint: 1 }))
                  handleGamePointChange(s, receiverState.gameScore);
                }}
              />
            </Stack>
            <Stack sx={{ flex: 1 }}>
              <GamePoint
                score={receiverState.gameScore}
                onChange={(s) => {
                  dispatch(addPoint({ serverType: "AdCourt", reason: "Point", gamePoint: 1 }))
                  handleGamePointChange(serverState.gameScore, s);
                }}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack sx={{ flex: 2 }}>
          <PointReason serverType="DeuceCourt" />
        </Stack>
      </Stack>
    </Paper>
  );
};

export default GameScore;
