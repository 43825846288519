import React from "react";
import Paper from "@mui/material/Paper";
import SetScore from "./SetScore";
import Stack from "@mui/material/Stack";
import { PlayerState } from "../store/MatchState";

interface MatchScoreProps {
  player1: PlayerState;
  player2: PlayerState;
}
const MatchScore: React.FC<MatchScoreProps> = ({ player1, player2 }) => {
  return (
    <>
      <Paper elevation={2}>
        <Stack spacing={1} sx={{ height: "100%", flex: 1 }}>
          <SetScore
            name={player1.name}
            scores={player1.setScore}
            isServer={player1.isServer}
          />
          <SetScore
            name={player2.name}
            scores={player2.setScore}
            isServer={player2.isServer}
          />
        </Stack>
      </Paper>
    </>
  );
};

export default MatchScore;
