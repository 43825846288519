import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

interface GamePointProps {
  score: number;
  onChange: (score: number) => void;
}
const GamePoint: React.FC<GamePointProps> = ({ score, onChange }) => {
  const getPointDisplay = (point: number): string => {
    switch (point) {
      case 0:
        return "0";
      case 1:
        return "15";
      case 2:
        return "30";
      case 3:
        return "40";
      case 4:
        return "AD";
      default:
        return "Invalid point";
    }
  };

  return (
    <Paper
      elevation={1}
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        width: "100%",
        height: 120,
      }}
    >
      <Typography
        variant="caption"
        sx={{
          position: "absolute",
          top: 2,
          right: 2,
        }}
      >
        POINT
      </Typography>
      <Stack
        className="score-number "
        sx={{
          justifyContent: "center",
          alignItems: "center",
          fontSize: "48px",
          height: "100%",
        }}
        onClick={() => {
          onChange(score + 1);
        }}
      >
        {getPointDisplay(score)}
      </Stack>
    </Paper>
  );
};

export default GamePoint;
