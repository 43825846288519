import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AddPointState, MatchState } from "./MatchState";
import { RootState } from "./store";

const initialPlayerState = {
  name: "",
  gameScore: 0,
  setScore: [0, 0, 0],
  courtType: "AdCourt",
  isServer: true,
};

const initialState: MatchState = {
  matchType: "Singles",
  player1: { ...initialPlayerState, name: "Player1", isServer: true },
  player2: { ...initialPlayerState, name: "Player2", isServer: false },
  gameRule: "",
  where: "",
  court: "",
  AdCourtPlayers: initialPlayerState,
  DeuceCourtPlayers: initialPlayerState,
  beginTime: "",
  matchTime: "",
  endTime: "",
  server: "AdCourt",
  isFirstServer: true,
  gameStatus: "",
};

export const matchSlice = createSlice({
  name: "match",
  initialState,
  reducers: {
    fault: (state) => {
      if (state.isFirstServer) {
        state.isFirstServer = false;
      }
    },
    point: (state, action) => {
      state.isFirstServer = true;
      if (state.server === "AdCourt") state.AdCourtPlayers.gameScore++;
      else state.DeuceCourtPlayers.gameScore++;
      console.log(action);
    },
    updateGameStatus: (state, action) => {
      state.gameStatus = action.payload;
    },
    addPoint: (state, action: PayloadAction<AddPointState>) => {
      if (action.payload.serverType === "AdCourt") {
        state.AdCourtPlayers.gameScore = action.payload.gamePoint;
      } else {
        state.DeuceCourtPlayers.gameScore = action.payload.gamePoint;
      }
    },
    changeServe: (state) => {
      state.player1.isServer = !state.player1.isServer;
      state.player2.isServer = !state.player2.isServer;
    },
  },
});

export const getMatchState = (state: RootState) => state.match;

export const { fault, point, updateGameStatus, addPoint, changeServe } = matchSlice.actions;

export default matchSlice.reducer;
